import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs';
import { forkJoin, from, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { concatMap } from 'rxjs/operators';

@Injectable()
export class UserApiService {

    url = environment.apiUrl;

    selectedUserTabStatus = {
        all: true,
        admin: true,
        verified: true,
        unverified: true,
        denied: true,
        operator : true
    };

    constructor(
        private http: HttpClient) {
    }


    login(data) {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            withCredentials: true,
            observe: 'response' as 'response'
        };
        return this.http
            .post(this.url + 'api/login_check', data, httpOptions);

    }

    register(data) {
        return this.http
            .post(this.url + 'api/register', data);
    }

    getUser(id) {
        return this.http
            .get(this.url + 'api/users/' + id);
    }

    editUser(data, id) {
        return forkJoin([
            this.http.patch(this.url + 'api/users/' + id + '/email', { email: data.email }),
            this.http.patch(this.url + 'api/users/' + id + '/firstname', { firstName: data.firstName }),
            this.http.patch(this.url + 'api/users/' + id + '/lastname', { lastName: data.lastName })]);

    }

    editUserPassword(newPassword, id) {
        return this.http.patch(this.url + 'api/users/' + id + '/password', { password: newPassword });
    }


    deleteUser(id) {
        return this.http
            .delete(this.url + 'api/users/' + id);
    }

    getUsers() {
        return this.http
            .get(this.url + 'api/users');
    }

    getUserById(id) {
        return this.http
            .get(this.url + 'api/users/' + id);
    }

    getUsersVerified() {
        return this.http
            .get(this.url + 'api/users/verified');
    }

    getUsersUnverified() {
        return this.http
            .get(this.url + 'api/users/unverified');
    }

    getUsersDenied() {
        return this.http
            .get(this.url + 'api/users/denied');
    }

    verifyUser(data, id) {
        // /api/users/{id}/verify
        // return this.http.post(this.url + 'api/users/' + userId + '/verify',{});
        return forkJoin([
            this.http.post(this.url + 'api/users/' + id + '/verify', {}),
            this.http.patch(this.url + 'api/users/' + id + '/email', { email: data.email }),
            this.http.patch(this.url + 'api/users/' + id + '/firstname', { firstName: data.firstName }),
            this.http.patch(this.url + 'api/users/' + id + '/lastname', { lastName: data.lastName })]);
    }

    promoteUserToAdmin(data, id) {
        return forkJoin([
            this.http.post(this.url + 'api/users/' + id + '/admin/promote', {}),
            this.http.patch(this.url + 'api/users/' + id + '/email', { email: data.email }),
            this.http.patch(this.url + 'api/users/' + id + '/firstname', { firstName: data.firstName }),
            this.http.patch(this.url + 'api/users/' + id + '/lastname', { lastName: data.lastName })]);

        // return this.http.post(this.url + 'api/users/' + userId + '/admin/promote',{});
    }

    revokeUserFromAdmin(data, id) {
        // return this.http.post(this.url + 'api/users/' + userId + '/admin/revoke',{});

        return forkJoin([
            this.http.post(this.url + 'api/users/' + id + '/admin/revoke', {}),
            this.http.patch(this.url + 'api/users/' + id + '/email', { email: data.email }),
            this.http.patch(this.url + 'api/users/' + id + '/firstname', { firstName: data.firstName }),
            this.http.patch(this.url + 'api/users/' + id + '/lastname', { lastName: data.lastName })]);
    }

    promoteUserToSystemAdmin(data, id) {
        return forkJoin([
            this.http.post(this.url + 'api/users/' + id + '/sysadmin/promote', {}),
            this.http.patch(this.url + 'api/users/' + id + '/email', { email: data.email }),
            this.http.patch(this.url + 'api/users/' + id + '/firstname', { firstName: data.firstName }),
            this.http.patch(this.url + 'api/users/' + id + '/lastname', { lastName: data.lastName })]);

        // return this.http.post(this.url + 'api/users/' + userId + '/admin/promote',{});
    }

    revokeUserFromSystemAdmin(data, id) {
        // return this.http.post(this.url + 'api/users/' + userId + '/admin/revoke',{});

        return forkJoin([
            this.http.post(this.url + 'api/users/' + id + '/sysadmin/revoke', {}),
            this.http.patch(this.url + 'api/users/' + id + '/email', { email: data.email }),
            this.http.patch(this.url + 'api/users/' + id + '/firstname', { firstName: data.firstName }),
            this.http.patch(this.url + 'api/users/' + id + '/lastname', { lastName: data.lastName })]);
    }


    denyUser(data, id) {
        // return this.http.post(this.url + 'api/users/' + userId + '/deny',{});

        return forkJoin([
            this.http.post(this.url + 'api/users/' + id + '/deny', {}),
            this.http.patch(this.url + 'api/users/' + id + '/email', { email: data.email }),
            this.http.patch(this.url + 'api/users/' + id + '/firstname', { firstName: data.firstName }),
            this.http.patch(this.url + 'api/users/' + id + '/lastname', { lastName: data.lastName })]);

    }

    allowUser(data, id) {
        // return this.http.post(this.url + 'api/users/' + userId + '/verify',{});

        return forkJoin([
            this.http.post(this.url + 'api/users/' + id + '/verify', {}),
            this.http.patch(this.url + 'api/users/' + id + '/email', { email: data.email }),
            this.http.patch(this.url + 'api/users/' + id + '/firstname', { firstName: data.firstName }),
            this.http.patch(this.url + 'api/users/' + id + '/lastname', { lastName: data.lastName })]);
    }

    editFullUser(data) {
        let requests = [];
        if (data.user.email && data.user.email !== data.oryginalUser.email) {
            requests.push(this.http.patch(this.url + 'api/users/' + data.id + '/email', { email: data.user.email }));
        }
        if (data.user.firstName && data.user.firstName !== data.oryginalUser.firstName) {
            requests.push(this.http.patch(this.url + 'api/users/' + data.id + '/firstname', { firstName: data.user.firstName }));
        }
        if (data.user.lastName && data.user.lastName !== data.oryginalUser.lastName) {
            requests.push(this.http.patch(this.url + 'api/users/' + data.id + '/lastname', { lastName: data.user.lastName }));
        }
        if (data.tier) {
            requests.push(this.editUserTier(data.tier, data.id));
        }

        requests.push(this.editUserManager(data.manager, data.id));

        if (requests.length === 1) {
            return requests[0];
        } else {
            return from(requests).pipe(
                concatMap(request => request)
            );
        }
    }

    editUserManager(managerId, id) {
        return this.http.patch(this.url + 'api/users/' + id + '/manager', { manager: managerId });
    }

    editUserTier(tierId, id) {
        return this.http.patch(this.url + 'api/users/' + id + '/tier', { tier: tierId });
    }

    getManagerForUser() {
        return this.http
            .get(this.url + 'api/users/managers');
    }

    resetPassword(type, value) {
        switch (type) {
            case 'email':
                return this.http.post(this.url + 'api/reset-password/', { email: value });
                break;
            case 'token':
                return this.http.post(this.url + 'api/reset-password/' + value.token, { password: value.password, companyName: value.companyName });
                break;
        }
    }
    standardorOperatorUser(id,data){
        return this.http.patch(this.url + 'api/users/' + id + '/role', {role : data} );
    }
}
