import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Storage} from '@ionic/storage';
import {Platform, ToastController, LoadingController} from '@ionic/angular';
import {BehaviorSubject, forkJoin, from, of} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UserApiService} from './user.api.service';
import {AlertController} from '@ionic/angular';
import {Crypt} from './utils/crypt';
import {max, mergeMap, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {RouteProvider} from './utils/route.provider';
import {StorageService} from './storage.service';
import {LoadingService} from './loading.service';
import {consoleTestResultHandler} from 'tslint/lib/test';
import {TiersApiService} from './tiers.api.service';
import {CustomTermsProvider} from './utils/customTerms.provider';


@Injectable()
export class AuthenticationService {

    authState = new BehaviorSubject(false);
    redirectState = new BehaviorSubject(null);
    refreshingState = new BehaviorSubject(false);

    isUserAdministrator = new BehaviorSubject(false);
    isUserSystemAdmin = new BehaviorSubject(false);
    isUserGlobalAdmin = new BehaviorSubject(false);
    helper = new JwtHelperService();
    loader;

    constructor(
        private router: Router,
        private storage: Storage,
        private cookies: CookieService,
        private userApi: UserApiService,
        public toastController: ToastController,
        private http: HttpClient,
        private crypt: Crypt,
        public alertController: AlertController,
        private routeHandler: RouteProvider,
        private platform: Platform,
        private storageService: StorageService,
        private loadingController: LoadingController,
        private customLoader: LoadingService,
        private tiersApi: TiersApiService,
        private customTerms: CustomTermsProvider
    ) {
        this.platform.ready().then(() => {
            this.ifLoggedIn();
        });
    }

    setRedirectLink(val) {
        this.redirectState.next(val);
    }

    ifLoggedIn() {
        if (this.cookies.get('rma-username')) {
            // if (this.storageService.get('rma-username')) {
            this.setAuthState(true);
        }
    }

    setAuthState(state: boolean) {
        this.authState.next(state);

    }

    setRefreshState(state: boolean) {
        this.refreshingState.next(state);

    }

    getMax(a, b) {
        return a.level < b.level ? -1 : a.level < b.level ? 1 : 0;
    }

    performChecks(user, maxLevel) {
        let data = {
            managerAssigned: false,
            tierAssigned: false,
            topTier: false
        };
        if (user.tier && user.tier.level) {
            data.tierAssigned = true;
        }
        if (user.manager && user.manager.fullName) {
            data.managerAssigned = true;
        }
        if (data.tierAssigned) {
            if (user.tier.level === maxLevel.level) {
                data.topTier = true;
            }
        }
        return of(data);
    }

    checkUserInfo() {


        this.tiersApi.getTiersList().pipe(mergeMap((next: []) => from(next).pipe(max(
            (a, b) => this.getMax(a, b)))))
            .pipe(mergeMap(maxLevel => this.userApi.getUser(this.getUserId())
                .pipe(mergeMap(myUser => this.performChecks(myUser, maxLevel)))))

            .subscribe(data => {
                if (!data.tierAssigned) {
                    this.showProfileRedirectionPopup();
                    return;
                }
                if (!data.managerAssigned) {
                    if (!data.topTier) {
                        this.showProfileRedirectionPopup();
                    }
                }
            });
    }

    showProfileRedirectionPopup() {
        this.alertController.create({
            header: 'Profile',
            message: 'Your profile is missing information.<br/>Please complete your profile to proceed',
            buttons: ['OK']
        }).then(alert => {
            alert.present().then(show => this.router.navigate(['profile']));
        });
    }

    isLoggedIn() {
        if (this.cookies.get('rma-username')) {
            // if (this.storageService.get('rma-username')) {
            return true;
        }
        return false;
    }

    login(user) {
        this.customLoader.present();
        this.userApi.login(user).subscribe(res => {
            this.customLoader.dismiss();
            // @ts-ignore
            this.setRefreshToken(res.body.refresh_token);
            // @ts-ignore
            const loggedUser = this.helper.decodeToken(res.body.token);
            let isAdministrator = false;
            let isSysAdmin = false;
            let isGlobalAdmin = false;
            let isAllowedAccess = false;
            if (loggedUser.roles.includes('ROLE_ADMINISTRATOR')) {
                isAllowedAccess = true;
                isAdministrator = true;
            }
            if (loggedUser.roles.includes('ROLE_SYSTEM_ADMIN')) {
                isAllowedAccess = true;
                isSysAdmin = true;
            }
            if (loggedUser.roles.includes('ROLE_GLOBAL_ADMIN')) {
                isAllowedAccess = true;
                isGlobalAdmin = true;
            }

            if (!isAllowedAccess) {
                this.presentAlert('This area is restricted for Administrators only. Please login to user area to proceed.', 'Server response');
                return;
            }

            this.setAccessToken(loggedUser.iat);
            // @ts-ignore
            this.cookies.set('rma-token', res.body.token);
            this.cookies.set('rma-expiry-token', loggedUser.exp);
            this.cookies.set('rma-username', this.crypt.encrypt(loggedUser.username));
            this.setUsername(loggedUser.username);
            this.setUserId(loggedUser.id);
            //
            if (isAdministrator) {
                this.isUserAdministrator.next(true);
                this.cookies.set('rma-role', this.crypt.encrypt('ROLE_ADMINISTRATOR'));
            }
            if (isSysAdmin) {
                this.isUserAdministrator.next(true);
                this.isUserSystemAdmin.next(true);
                this.cookies.set('rma-role-2', this.crypt.encrypt('ROLE_SYSTEM_ADMIN'));

            }
            if (isGlobalAdmin) {
                this.isUserAdministrator.next(true);
                this.isUserGlobalAdmin.next(true);
                this.cookies.set('rma-role-3', this.crypt.encrypt('ROLE_GLOBAL_ADMIN'));

            }
            this.setAuthState(true);
            const redirectRoute = this.routeHandler.getRoute();
            if (!this.customTerms.initialised.getValue()) {
                this.customTerms.init();
            }
            if (redirectRoute) {
                this.router.navigate([redirectRoute.url[0].path + '/' + redirectRoute.url[1].path]);

            } else if (this.routeHandler.getLocationRoute()) {
                this.router.navigate([this.routeHandler.getLocationRoute()]);
            } else {
                this.router.navigate(['home']);
            }
            this.checkUserInfo();
        }, fail => {
            this.customLoader.dismiss();
            console.error(fail);
            let errorMessage = null;
            if (fail.error) {
                if (fail.error.message) {
                    errorMessage = fail.error.message;
                }
            }
            let errorTitle = null;
            if (!errorMessage) {
                errorMessage = 'A network error occurred while communicating with the server. Please check your internet connection and try again.';
            } else {
                errorTitle = 'Server response';
            }
            this.presentAlert(errorMessage, errorTitle);
        });
    }

    setUsername(username) {
        this.cookies.set('rma-username', this.crypt.encrypt(username));
    }

    getLoggedIn() {
        return this.crypt.decrypt(this.cookies.get('rma-username'));
    }

    getRefreshToken() {
        return this.crypt.decrypt(this.cookies.get('rma-refresh-token'));
    }

    setRefreshToken(token) {
        this.cookies.set('rma-refresh-token', this.crypt.encrypt(token));
    }

    getAccessToken() {
        return this.cookies.get('rma-access-token');
    }

    setAccessToken(token) {
        this.cookies.set('rma-access-token', token);
    }

    setUserId(id) {
        this.cookies.set('rma-user', this.crypt.encrypt(id));
    }

    getUserId() {
        return this.crypt.decrypt(this.cookies.get('rma-user'));
    }

    logout() {
        this.cookies.deleteAll();
        this.router.navigate(['login']);
        this.setAuthState(false);
        this.isUserAdministrator.next(false);
        this.isUserSystemAdmin.next(false);
        this.isUserGlobalAdmin.next(false);
    }

    isAuthenticated() {
        return this.authState.value;
    }

    register(user) {
        delete user.recaptcha;
        delete user.confirmPassword;
        this.userApi.register(user).subscribe(res => {
                this.presentAlert('Please check your inbox to confirm your email address.', 'Verification pending').then(r => this.router.navigate(['login']));
            },
            fail => {
                let message = fail.error.message;
                if (fail.error.errors) {
                    message = '';
                    // tslint:disable-next-line:forin
                    for (const key in fail.error.errors) {
                        message += fail.error.errors[key] + ' </br>';
                    }
                }
                this.presentAlert(message);
            });
    }

    isAdmin() {
        return this.isUserAdministrator.value;
    }

    isAdminLogged() {
        let isAdmin = false;
        if (this.crypt.decrypt(this.cookies.get('rma-role')) === 'ROLE_ADMINISTRATOR') {
            isAdmin = true;
        }
        if (this.crypt.decrypt(this.cookies.get('rma-role-2')) === 'ROLE_SYSTEM_ADMIN') {
            isAdmin = true;
        }
        if (this.crypt.decrypt(this.cookies.get('rma-role-3')) === 'ROLE_GLOBAL_ADMIN') {
            isAdmin = true;
        }
        return isAdmin;
    }

    isSystemAdmin() {
        return this.isUserSystemAdmin.value;
    }

    isGlobalAdmin() {
        return this.isUserGlobalAdmin.value;
    }


    isSystemAdminLogged() {
        return this.crypt.decrypt(this.cookies.get('rma-role-2')) === 'ROLE_SYSTEM_ADMIN';
    }

    isGlobalAdminLogged() {
        return this.crypt.decrypt(this.cookies.get('rma-role-3')) === 'ROLE_GLOBAL_ADMIN';
    }

    refresh() {
        return this.http.post<any>(environment.apiUrl + `token/refresh`, {
            refresh_token: this.getRefreshToken()
        }).pipe(tap((token) => {
            this.setAccessToken(token.token);
        }));
    }

    async presentAlert(msg, title?, callback?) {
        const alert = await this.alertController.create({
            header: title ? title : 'Something went wrong',
            message: msg,
            buttons: [
                {
                    text: 'OK',
                    handler: () => {
                    }
                }
            ]
        });

        await alert.present();
    }

    async presentToast() {
        const toast = await this.toastController.create({
            message: 'User registration successful',
            duration: 2000
        });
        await toast.present();
    }
}
